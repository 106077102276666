import React from "react";
import { applewoman as AppleWoman } from "../assets/images/shapes/shapes";
import { hourglasswoman as HourglassWoman } from "../assets/images/shapes/shapes";
import { rectanglewoman as RectangleWoman } from "../assets/images/shapes/shapes";
import { pearwoman as PearWoman } from "../assets/images/shapes/shapes";
import { appleman as AppleMan } from "../assets/images/shapes/shapes_man";
import { pearman as PearMan } from "../assets/images/shapes/shapes_man";
import { rectangleman as RectangleMan } from "../assets/images/shapes/shapes_man";
import SaizImageLoader from "./saizImageLoader";
import { v4 as uuid } from "uuid";

interface SaizBodyTypeProps {
  title: string;
  gender: string;
  type: string;
  className?: string;
  desc: string;
}
const SaizType = ({
  title,
  type,
  gender,
  className,
  desc,
}: SaizBodyTypeProps) => {
  const default_image = `${type}_${gender}.png`;
  const unique_id = uuid();
  const renderShape = (shape: string) => {
    const all: any = {
      apple_woman: AppleWoman,
      hourglass_woman: HourglassWoman,
      rectangle_woman: RectangleWoman,
      pear_woman: PearWoman,
      apple_man: AppleMan,
      rectangle_man: PearMan,
      pear_man: RectangleMan,
    };
    const Type = all[shape];

    return <Type className="saiz-shape-container" />;
  };

  return (
    <div className={`saiz-shape-container ${className}`}>
      <h2
        ref={(node) => {
          node?.style.setProperty("font-size", "14px", "important");
        }}
        className="saiz-header-light"
        style={{ marginBottom: "8px" }}
      >
        {title}
      </h2>
      <div
        style={{
          position: "relative",
          height: "138px",
          width: "108px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            zIndex: 2,
            marginTop: "3px",
          }}
        >
          <SaizImageLoader item={default_image} alt={gender} />
        </div>
        {renderShape(`${type}_${gender}`)}
      </div>
      <p
        className="saiz-body-desc"
        style={{
          marginTop: "16px",
          textAlign: "center",
          width: "158px",
        }}
      >
        {desc}
      </p>
    </div>
  );
};

export default SaizType;
