import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ModalContext } from "../context/modalContext";
import { BrandConstants, ScreenTypeDto } from "../models";
import { createPortal } from "react-dom";
import { findBrandConfigByNameAndElementType } from "../utils/utils";
import { ElementType } from "../utils/config";
import { Bognermutation } from "./bognerMutation";

const MopLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    onOpen,
    isOpen,
    productCode,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  return (
    <div
      style={{ width: "100%" }}
      onClick={() => {
        if (cal?.data?.data?.measuredSize) {
          if (!cal?.isLoaded) {
            getMeasurements();
          }
          setStep(ScreenTypeDto.Summary);
          setReturnToScreen(ScreenTypeDto.Summary);
          saizEvents(ScreenTypeDto.Summary);
        } else {
          setStep(ScreenTypeDto.ScreenOne);
          setReturnToScreen(ScreenTypeDto.ScreenOne);
          saizEvents(ScreenTypeDto.ScreenOne);
        }
        setModal(true);
      }}
      id="saiz-floating-button"
      saiz-widget-flag={
        product?.isLoaded && product?.product?.isActive ? "true" : "false"
      }
      saiz-widget-productcode={productCode}
      saiz-widget-nosize={cal?.isLoaded && cal?.data?.noSize ? "true" : "false"}
      saiz-widget-recommendation={
        !cal?.isLoaded || cal?.data?.noSize
          ? ``
          : `${cal?.data?.data?.measuredSize}`
      }
    ></div>
  );
};
const BognerLinkContent = (props: any) => {
  const {
    cal,
    getMeasurements,
    setStep,
    setReturnToScreen,
    saizEvents,
    product,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const setLink = () => {
    const Svg =
      '<svg xmlns="http://www.w3.org/2000/svg" style="margin-right:6px; margin-left:8px; margin-top:8px;" name="recommender_svg" width="17" height="16" viewBox="0 0 21 20" fill="none"><g clip-path="url(#clip0_2615_14223)"><path d="M17.2471 8.7914C16.9987 8.7914 16.7955 8.59785 16.7955 8.36129V4.56774H12.3878C12.1394 4.56774 11.9362 4.37419 11.9362 4.13763C11.9362 3.90108 12.1394 3.70753 12.3878 3.70753H17.2471C17.4955 3.70753 17.6987 3.90108 17.6987 4.13763V8.36129C17.6987 8.59785 17.4955 8.7914 17.2471 8.7914Z" fill="black"/><path d="M17.2471 16.2925H12.3878C12.1394 16.2925 11.9362 16.0989 11.9362 15.8624C11.9362 15.6258 12.1394 15.4323 12.3878 15.4323H16.7955V11.6387C16.7955 11.4022 16.9987 11.2086 17.2471 11.2086C17.4955 11.2086 17.6987 11.4022 17.6987 11.6387V15.8624C17.6987 16.0989 17.4955 16.2925 17.2471 16.2925Z" fill="black"/><path d="M8.61224 16.2925H3.75288C3.5045 16.2925 3.30127 16.0989 3.30127 15.8624V11.6387C3.30127 11.4022 3.5045 11.2086 3.75288 11.2086C4.00127 11.2086 4.2045 11.4022 4.2045 11.6387V15.4323H8.61224C8.86062 15.4323 9.06385 15.6258 9.06385 15.8624C9.06385 16.0989 8.86062 16.2925 8.61224 16.2925Z" fill="black"/><path d="M3.75288 8.7914C3.5045 8.7914 3.30127 8.59785 3.30127 8.36129V4.13763C3.30127 3.90108 3.5045 3.70753 3.75288 3.70753H8.61224C8.86062 3.70753 9.06385 3.90108 9.06385 4.13763C9.06385 4.37419 8.86062 4.56774 8.61224 4.56774H4.2045V8.36129C4.2045 8.59785 4.00127 8.7914 3.75288 8.7914Z" fill="black"/><path d="M20.5484 20H0.451613C0.203226 20 0 19.8065 0 19.5699V0.430108C0 0.193548 0.203226 0 0.451613 0H20.5484C20.7968 0 21 0.193548 21 0.430108V19.5699C21 19.8065 20.7968 20 20.5484 20ZM0.903226 19.1398H20.0968V0.860215H0.903226V19.1398Z" fill="black"/></g>  <defs>  <clipPath id="clip0_2615_14223"><rect width="21" height="20" fill="white"/></clipPath></defs></svg>';

    const target = document.querySelector(
      "button[class='productDetailsCockpitColBottom__fitFinder js-fitFinderTrigger']"
    );
    const parentTarget = document.querySelector(
      "span[class='productDetailsCockpitColBottom__fitFinderWrapper']"
    );
    const recommenderSvg = document.querySelector(
      "svg[name='recommender_svg']"
    );
    if (target && parentTarget) {
      const text =
        !cal?.isLoaded || cal?.data?.noSize
          ? t("question")
          : t("answer") + " " + cal?.data?.data?.measuredSize;

      if (product?.product?.isActive) {
        if (!recommenderSvg) {
          parentTarget.insertAdjacentHTML("beforebegin", Svg);
        }
        target.innerHTML = text;
        target.addEventListener("click", () => {
          if (cal?.data?.data?.measuredSize) {
            if (!cal?.isLoaded) {
              getMeasurements();
            }
            setStep(ScreenTypeDto.Summary);
            saizEvents(ScreenTypeDto.Summary);
            setReturnToScreen(ScreenTypeDto.Summary);
          } else {
            setStep(ScreenTypeDto.ScreenOne);
            saizEvents(ScreenTypeDto.ScreenOne);
            setReturnToScreen(ScreenTypeDto.ScreenOne);
          }
          setModal(true);
        });
      } else {
        recommenderSvg?.remove();
        target.innerHTML = "";
      }
    }
  };
  useEffect(() => {
    setLink();
  }, [product, cal]);
  window.addEventListener("saiz-widget-bogner", setLink);
  return <Bognermutation />;
};

const CommonLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    onOpen,
    isOpen,
    brandCode,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const target = document
        ?.querySelector(`h2[class='ctl-title']`)
        ?.getBoundingClientRect().top;

      if (window.innerWidth > 600 && target) {
        setVisible(target > 450);
      }
    };
    if (brandCode === BrandConstants.RICHROYALDE) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div>
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span
                  style={{
                    fontWeight: "var(--saiz_link_desktop_font_weight)",
                  }}
                  className="saiz-button-calculate"
                >
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span className="saiz-best-size">
                  {cal?.data?.data?.measuredSize}
                </span>
                <span className="saiz-best-size-text"> {t("isYourBest")}</span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const TTLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    auth,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    onOpen,
    isOpen,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    const target = document
      ?.querySelector(`span[title='Größentabelle']`)
      ?.getBoundingClientRect().top;

    if (window.innerWidth > 600 && target) {
      setVisible(target > 370);
    } else if (window.innerWidth < 600 && target) {
      setVisible(target > 370);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty("background", "#001931", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty("top", "350px", "important");
            node?.style.setProperty("z-index", "10", "important");
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span className="saiz-button-calculate">
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size"
                  ref={(node) => {
                    node?.style.setProperty(
                      "background",
                      "#001931",
                      "important"
                    );
                  }}
                >
                  {cal?.data?.data?.measuredSize}
                </span>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                  }}
                >
                  {" "}
                  {t("isYourBest")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
const SOLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    saizEvents,
    brandCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    const target = document
      ?.querySelector(`div[data-e2e='carousel-similar-products']`)
      ?.getBoundingClientRect().top;

    if (window.innerWidth > 600 && target) {
      setVisible(target > 525);
    } else if (window.innerWidth < 600 && target) {
      setVisible(target > 525);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty("background", "#000000", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty("top", "400px", "important");
            node?.style.setProperty("z-index", "10", "important");
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span className="saiz-button-calculate">
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size"
                  ref={(node) => {
                    node?.style.setProperty(
                      "background",
                      "#000000",
                      "important"
                    );
                  }}
                >
                  {cal?.data?.data?.measuredSize}
                </span>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                  }}
                >
                  {" "}
                  {t("isYourBest")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const TchiboLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    saizEvents,
    brandCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [hover, setHover] = useState(false);

  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          ref={(node) => {
            node?.style.setProperty("position", "initial", "important");
            node?.style.setProperty("display", "flex", "important");
            node?.style.setProperty("background", "transparent", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty("height", "unset", "important");
            node?.style.setProperty(
              "color",
              hover ? "#64696d" : "#1d2429",
              "important"
            );
            node?.style.setProperty(
              "justify-content",
              "flex-start",
              "important"
            );
            node?.style.setProperty(
              "text-decoration",
              "underline",
              "important"
            );
          }}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className="saiz-tchibo-link-button"
        >
          <img
            ref={(node) => {
              node?.style.setProperty(
                "background",
                hover ? "#e9eaea" : "#fff",
                "important"
              );
              node?.style.setProperty("border-radius", "50%", "important");
              node?.style.setProperty("margin-right", "5px", "important");
            }}
            src={`${process.env.REACT_APP_IMAGES_URL}/saiz-tchibo-icon.svg`}
            alt="icon"
          />
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
              node?.style.setProperty("display", "block", "important");
            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span
                  className="saiz-button-calculate
                
                "
                  ref={(node) => {
                    node?.style.setProperty("margin", "0px", "important");
                    node?.style.setProperty("font-weight", "300", "important");
                    node?.style.setProperty("font-size", "16px", "important");
                  }}
                >
                  {t("calculateSize")}
                </span>
                <span
                  className="saiz-button-calculate-mobile"
                  ref={(node) => {
                    node?.style.setProperty("margin", "0px", "important");
                    node?.style.setProperty("font-weight", "300", "important");
                    node?.style.setProperty("font-size", "14px", "important");
                    node?.style.setProperty(
                      "text-decoration",
                      "underline",
                      "important"
                    );
                  }}
                >
                  {t("calculateSize")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                    node?.style.setProperty("color", "#21292F", "important");
                    node?.style.setProperty("font-size", "16px", "important");
                    window.innerWidth < 768
                      ? node?.style.setProperty(
                          "font-size",
                          "14px",
                          "important"
                        )
                      : node?.style.setProperty(
                          "font-size",
                          "16px",
                          "important"
                        );

                    node?.style.setProperty(
                      "padding-right",
                      "0px",
                      "important"
                    );
                    node?.style.setProperty("font-weight", "300", "important");
                  }}
                >
                  {t("isYourBest")}{" "}
                </span>
                <span
                  style={{
                    margin: "0px 0px",
                    fontWeight: 300,
                    color: "#21292F",
                  }}
                  ref={(node) => {
                    window.innerWidth < 768
                      ? node?.style.setProperty(
                          "font-size",
                          "14px",
                          "important"
                        )
                      : node?.style.setProperty(
                          "font-size",
                          "16px",
                          "important"
                        );
                  }}
                >
                  {cal?.data?.data?.measuredSize + "  "}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const SaizLink = (props: any) => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const brandConfig = findBrandConfigByNameAndElementType(
    props.brandCode,
    ElementType.SaizButton
  );
  useEffect(() => {
    if (!brandConfig) return;
    const targetEl = document.querySelector(brandConfig.targetQuerySelector);
    if (targetEl) {
      setTarget(targetEl as HTMLDivElement);
    }
  }, [props.productCode]);

  const getLink = () => {
    switch (props.brandCode) {
      case BrandConstants.MARCOPOLO:
        return <MopLinkContent {...props} target={target} />;
      case BrandConstants.TOMTAILOR:
        return <TTLinkContent {...props} target={target} />;
      case BrandConstants.SOLIVER:
      case BrandConstants.COMMA:
        return <SOLinkContent {...props} target={target} />;
      case BrandConstants.TCHIBO:
        return <TchiboLinkContent {...props} target={target} />;
      case BrandConstants.BOGNER:
      case BrandConstants.FIREICE:
        return <BognerLinkContent {...props} target={target} />;
      default:
        return <CommonLinkContent {...props} target={target} />;
    }
  };
  if (target) {
    target.style.position = "relative";
    return createPortal(getLink(), target);
  }
  return getLink();
};
const mapStateToProps = (state: any, ownProps: any) => {
  return {
    cal: state.CalculatorReducer,
    product: state.ProductReducer,
    auth: state.AuthReducer,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(SaizLink);
