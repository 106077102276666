import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
export const Bognermutation = () => {
  const [reloadTrigger, setReloadTrigger] = useState(uuid());
  const handleAttributeChange = () => {
    setTimeout(() => {
      setReloadTrigger(uuid());
      window.dispatchEvent(new Event("saiz-widget-bogner"));
    }, 6000);
  };

  useEffect(() => {
    // 1. Select the element using standard class selectors
    //    For multiple classes, use dot notation (no spaces):
    //    "div.classOne.classTwo"
    const targetElement = document.querySelector(
      "div[class='productDetailsCockpitColBottom__sizeSelectWrapper js-sizeSelector']"
    );

    // 2. If not found, warn and return
    if (!targetElement) {
      console.warn(
        "Element with classes 'productDetailsCockpitColBottom__sizeSelectWrapper js-sizeSelector' not found."
      );
      return;
    }

    // 3. Create the MutationObserver
    const observer = new MutationObserver((mutations: any) => {
      mutations.forEach((mutation: any) => {
        if (mutation.type === "attributes") {
          console.log(
            `Attribute "${mutation.attributeName}" changed.`,
            "New value:"
          );
          handleAttributeChange();
        }
      });
    });
    // 4. Observe the element for attribute changes
    observer.observe(targetElement, {
      attributes: true,
      childList: false,
      subtree: false,
    });

    // 5. Clean up when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [reloadTrigger, setReloadTrigger]);
  return null;
};
