import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductActions } from "../redux/actions";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import {
  ScreenTypeDto,
  MeasurementsTypeEnum,
  SizeTypeDto,
  BrandConstants,
} from "../models";
import { connect, useDispatch } from "react-redux";
import {
  SaizImageLoader,
  SaizMainContainer,
  SaizSizeBox,
  SaizBundleBox,
} from "../components";
import GarmentImage from "./garmentImage";
import ArrowLines from "./arrowLines";
import InfoButton from "../components/InfoButton";
import { NudgeTypeDto } from "../swagger";
import { maxItemsPerSlide, THRESHOLD_SIZE_DIFF } from "../config/garmentConfig";

const getSafeSliderIndex = (
  sliderIndex: number,
  length: number,
  itemsPerSlide: number
) => {
  if (sliderIndex < 0) return 0;
  if (sliderIndex + itemsPerSlide > length)
    return Math.max(0, length - itemsPerSlide);
  return sliderIndex;
};

function ScreenSummary(props: any) {
  const transparentPixel = "transparent.png";
  const approve = "icon_approve.png";
  const disapprove = "icon_dissaprove.png";
  const caretLeft = "caret_left.png";
  const caretRight = "caret_right.png";
  const fits = "icon_fits.png";
  const dispatch = useDispatch<any>();
  const { t } = useTranslation("common");
  const {
    setStep,
    setReturnStep,
    product,
    onEdit,
    shopNow,
    cal,
    auth,
    onChangeAttributeCode,
    brandCode,
    lang,
    productCode,
  } = props;

  const [originalMeasuredSize, setOriginalMeasuredSize] = useState<string>();
  const [measuredSize, setMeasuredSize] = useState<string>();
  const [fitResult, setFitResult] = useState<any>({});
  const [sliderIndex, setSliderIndex] = useState(0);
  const [nudgeIndex, setNudgeIndex] = useState(0);

  const [nudge, setNudge] = useState<any>(
    product?.product?.nudges?.[nudgeIndex]
  );
  useEffect(() => {
    if (cal?.loading) return;
    if (!cal?.isLoaded) {
      setStep(ScreenTypeDto.ScreenOne);
    }
    setProductSizes(getProductSizes());
  }, [productCode]);

  const getProductSizes = () => {
    const products: { [key: string]: number } = {};
    product?.product?.productMeasurements.map((item: any, index: number) => {
      products[item.productSize] = index;
    });
    products[SizeTypeDto.LOUT] = Object.keys(products).length;
    products[SizeTypeDto.SOUT] = -1;
    return products;
  };

  const [productSizes, setProductSizes] = useState<{
    [key: string]: number;
  }>(getProductSizes());

  const productSizesArray = useMemo(
    () =>
      Object.keys(getProductSizes()).filter(
        (item) => item !== SizeTypeDto.LOUT && item !== SizeTypeDto.SOUT
      ),
    [productSizes, productCode]
  );

  const displayMeasurement = (item: string) => {
    const argAltered = item === "length" ? "torsolength" : item?.toLowerCase();
    const measured = cal?.data?.data?.calculatedBodyMeasurement[argAltered];
    const findRange = cal?.data?.data?.calculatedProductRanges[
      argAltered + "Range"
    ]?.filter((range: any) => {
      return measured >= range.item1 && measured <= range.item2;
    });

    if (!findRange.length) {
      const outOfRange = cal?.data?.data?.calculatedProductRanges[
        argAltered + "Range"
      ].map((item: any) => item.item1);
      const min = Math.min(...outOfRange);
      const max = Math.max(...outOfRange);
      if (measured < min) {
        return [{ item3: SizeTypeDto.SOUT }];
      } else {
        return [{ item3: SizeTypeDto.LOUT }];
      }
    }
    return findRange;
  };

  useEffect(() => {
    if (cal?.loading) return;
    if (cal?.data?.data?.noSize) {
      setStep(ScreenTypeDto.ScreenFour);
    } else {
      setReturnStep(ScreenTypeDto.Summary);
    }
  }, []);

  useEffect(() => {
    if (cal?.loading) return;
    const measured = cal?.data?.data?.measuredSize;
    setOriginalMeasuredSize(measured);
    changeMeasuredSize(measured);
    if (brandCode === BrandConstants.MARCOPOLO) {
      const container = document.getElementById("saiz-widget-container");
      const isOutOfRange = cal?.data?.data?.outOfRange ? "true" : "false";
      container?.setAttribute("outOfRange", isOutOfRange);
    }
    const currentSliderIndex = productSizes[measured] ?? 0;
    const sliderIndex = getSafeSliderIndex(
      currentSliderIndex,
      productSizesArray.length,
      maxItemsPerSlide
    );
    setSliderIndex(sliderIndex);
  }, [cal?.loading, productSizes, productSizesArray, productCode]);

  useEffect(() => {
    if (cal.loading) return;
    const measured = cal?.data?.data?.measuredSize;
    changeMeasuredSize(measured);
  }, [productSizes, productCode]);

  const changeMeasuredSize = (measured: string) => {
    setMeasuredSize(measured);
    const newFitResult: { [key: string]: number } = {};

    /*  
        Produces an object of type 
        {Waist: 0, Hip: -1, Length: 4}
        indicating the difference between the measured and the actual size
    */
    Object.keys(MeasurementsTypeEnum).forEach((item, index) => {
      if (cal?.data["display" + item]) {
        //`result` is an array containing one or more ranges that fit for that measurements
        const result = displayMeasurement(item);

        let fitResultLocal: any =
          productSizes[measured] - productSizes[result[0].item3];

        const inRange: boolean = result.some(
          (itemArrays: any) => itemArrays?.item3 === measured
        );

        if (inRange) {
          fitResultLocal = 0;
        }

        if (fitResultLocal > THRESHOLD_SIZE_DIFF) {
          fitResultLocal = THRESHOLD_SIZE_DIFF;
        }
        if (fitResultLocal < 0 - THRESHOLD_SIZE_DIFF) {
          fitResultLocal = 0 - THRESHOLD_SIZE_DIFF;
        }

        newFitResult[item] = fitResultLocal;
      }
    });
    setFitResult(newFitResult);
  };
  const renderBigSizeBox = () => {
    const renderBox = (
      <div
        className="big-size-box"
        style={{
          top:
            product?.product?.hasNudges && product?.product?.showNudges
              ? "15%"
              : "30%",
        }}
      >
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            height: "40px",
            wordBreak: "break-word",
            textTransform: "uppercase",
            fontSize: "11px",
            fontWeight: "600",
            margin: " 10px auto",
            textAlign: "center",
          }}
        >
          {renderBoxTitle(
            productSizes[measuredSize!] - productSizes[originalMeasuredSize!]
          )}
        </span>
        <div
          style={{
            filter: `drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.10))`,
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "4px",
            minWidth: "85px",
            padding: "0px 10px",
            height: "85px",
            position: "relative",
          }}
        >
          <SaizImageLoader
            key={"size1"}
            item={renderIcon(
              productSizes[measuredSize!] - productSizes[originalMeasuredSize!]
            )}
            alt="recommended size"
            style={{
              width: "15px",
              height: "15px",
              position: "absolute",
              zIndex: 10,
              top: "-5px",
              right: "-5px",
              display:
                productSizes[measuredSize!] >
                productSizes[originalMeasuredSize!]
                  ? "none"
                  : "block",
            }}
          />
          <p
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "40px",
            }}
          >
            {measuredSize}
          </p>
        </div>
      </div>
    );
    return renderBox;
  };

  const renderClippedSizes = () => {
    const clipped = productSizesArray.slice(
      sliderIndex,
      sliderIndex! + maxItemsPerSlide
    );
    const result = clipped.map((item) => {
      return (
        <SaizSizeBox
          key={`${item}clip`}
          size={item}
          diff={productSizes[item] - productSizes[originalMeasuredSize ?? ""]}
          originalMeasuredSize={originalMeasuredSize!}
          onSizeChange={() => changeMeasuredSize(item)}
          productSizes={productSizes}
          outOfRange={cal?.data?.data?.outOfRange}
        />
      );
    });
    return <>{result}</>;
  };

  const changeSliderIndex = (arg: number) => {
    setSliderIndex((prev) => {
      return getSafeSliderIndex(
        prev! + arg,
        productSizesArray.length,
        maxItemsPerSlide
      );
    });
  };

  const renderIcon = (diff: number) => {
    if (cal?.data?.data?.outOfRange) return disapprove;
    const icon =
      diff === 0
        ? approve
        : Math.abs(diff) === 1
        ? fits
        : diff < 0
        ? disapprove
        : disapprove;
    return icon;
  };

  const renderBoxTitle = (diff: number) => {
    const title =
      diff === 0
        ? t("summary.bestOption")
        : Math.abs(diff) === 1
        ? brandCode === BrandConstants.MARCOPOLO && cal?.data?.data?.outOfRange
          ? ""
          : t("summary.alsoFits")
        : diff < 0
        ? t("summary.notRecommended")
        : "";

    return title;
  };

  const handlePreviousNudgeClick = () => {
    if (nudgeIndex > 0) {
      setNudge(product?.product?.nudges?.[nudgeIndex - 1]);
      setNudgeIndex(nudgeIndex - 1);
    }
  };
  const handleNextNudgeClick = () => {
    if (nudgeIndex < product?.product?.nudges?.length) {
      setNudge(product?.product?.nudges?.[nudgeIndex + 1]);
      setNudgeIndex(nudgeIndex + 1);
    }
  };

  const getInfluencerText = (name?: string, size?: string) => {
    let toastText = t("nudge.influencer");
    toastText = toastText.replace("#name", name ?? "");
    toastText = toastText.replace("#size", size ?? "");
    return toastText;
  };
  const getNudgeText = (nudge: any) => {
    if (nudge?.nudgeType === NudgeTypeDto.Message) return nudge?.message;
    return getInfluencerText(nudge?.message, nudge?.size);
  };
  return (
    <SaizMainContainer>
      <h2
        style={{
          alignItems: "center",
          position: "relative",
          maxWidth: "80%",
          textAlign: "center",
        }}
        ref={(node: any) => {
          (brandCode === BrandConstants.SCHOEFFEL ||
            brandCode === BrandConstants.BOGNER ||
            brandCode === BrandConstants.FIREICE) &&
            node?.style.setProperty("font-size", "20px", "important");
        }}
        className={brandCode === BrandConstants.MARCOPOLO ? "uppercase" : ""}
      >
        {brandCode === BrandConstants.MARCOPOLO && cal?.data?.data?.outOfRange
          ? t("summary.outOfRangeHeading")
          : t("summary.heading")}
        {brandCode === BrandConstants.JACKWOLFSKIN && <InfoButton />}
      </h2>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <div style={{ position: "relative" }}>
          <div className="box">
            {product?.productAttributes.map((item: any) => (
              <SaizBundleBox
                key={`${item?.productCode}-sizebox`}
                size={item?.productName}
                className={
                  auth?.localStorage?.attributeCode === item?.productCode
                    ? "selected-bundle bundle-size-box"
                    : "bundle-size-box"
                }
                onSizeChange={() => {
                  dispatch(
                    ProductActions.selectProductFromAttributes(
                      item?.productCode
                    )
                  );
                  onChangeAttributeCode(item?.productCode);
                }}
              />
            ))}
          </div>
          <GarmentImage />
          {cal?.data?.displayChest && (
            <ArrowLines
              arrayOfChoice={MeasurementsTypeEnum.Chest}
              measuredSize={measuredSize}
              productCode={product?.product?.productCode}
              outOfRange={cal?.data?.data?.outOfRange}
              minMax={cal?.data?.data?.minMax}
            />
          )}
          {cal?.data?.displayWaist && (
            <ArrowLines
              arrayOfChoice={MeasurementsTypeEnum.Waist}
              measuredSize={measuredSize}
              productCode={product?.product?.productCode}
              outOfRange={cal?.data?.data?.outOfRange}
              minMax={cal?.data?.data?.minMax}
            />
          )}
          {cal?.data?.displayHip && (
            <ArrowLines
              arrayOfChoice={MeasurementsTypeEnum.Hip}
              measuredSize={measuredSize}
              productCode={product?.product?.productCode}
              outOfRange={cal?.data?.data?.outOfRange}
              minMax={cal?.data?.data?.minMax}
            />
          )}
          {cal?.data?.displayLength && (
            <ArrowLines
              arrayOfChoice={MeasurementsTypeEnum.Length}
              measuredSize={measuredSize}
              productCode={product?.product?.productCode}
              outOfRange={cal?.data?.data?.outOfRange}
              minMax={cal?.data?.data?.minMax}
            />
          )}
        </div>
        <div
          style={{
            marginTop: 0,
            textAlign: "center",
            gap: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{ color: "var(--saiz-colors-brand-500)", fontSize: "13px" }}
          >
            {t("summary.subHeading1")}
            <span
              style={{
                fontSize: "13px",
                padding: "4px 4px",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "600",
                color: "rgb(33, 33, 33)",
              }}
              onClick={() => {
                onEdit();
              }}
              id="btnEdit"
            >
              {t("edit")}
            </span>
          </p>
        </div>
        <p className="summary-subheading">{t("summary.subHeading2")}</p>
        {renderBigSizeBox()}
        <div
          style={{
            marginTop: 0,
            gap: "9px",
            fontSize: "var(--saiz-fontSizes-sm)",
            paddingTop: "5px",
            display: "flex",
            maxWidth: "90%",
            alignItems: "center",
          }}
        >
          {Object.keys(productSizes).length > maxItemsPerSlide && (
            <SaizImageLoader
              className={
                sliderIndex! <= 0 ? "saiz-caret-disabled" : "saiz-caret"
              }
              item={caretLeft}
              onClick={() => {
                if (sliderIndex! > 0) {
                  changeSliderIndex(-1);
                }
              }}
              style={{
                width: "10px",
                height: "17px",
                marginRight: "5px",
              }}
              alt="image"
            />
          )}

          {renderClippedSizes()}
          {productSizesArray?.length > maxItemsPerSlide && (
            <SaizImageLoader
              className={
                sliderIndex! < productSizesArray?.length - maxItemsPerSlide
                  ? "saiz-caret"
                  : "saiz-caret-disabled"
              }
              item={caretRight}
              alt="right"
              onClick={() => {
                if (
                  sliderIndex! <
                  productSizesArray?.length - maxItemsPerSlide
                ) {
                  changeSliderIndex(1);
                }
              }}
              style={{
                width: "10px",
                height: "17px",
              }}
            />
          )}
        </div>
        {product?.product?.hasNudges && product?.product?.showNudges && (
          <div className="summary-screen-nudging-container">
            {product?.product?.nudges?.length > 1 && (
              <button
                disabled={product?.product?.nudges?.length === 1 || !nudgeIndex}
                className="left-button"
                onClick={handlePreviousNudgeClick}
              >
                <FaAngleLeft size={24} />
              </button>
            )}

            <p className="summary-screen-nudging-p">{getNudgeText(nudge)}</p>
            {product?.product?.nudges?.length > 1 && (
              <button
                disabled={
                  product?.product?.nudges?.length === 1 ||
                  nudgeIndex === product?.product?.nudges?.length - 1
                }
                className="right-button"
                onClick={handleNextNudgeClick}
              >
                <FaAngleRight size={24} />
              </button>
            )}
          </div>
        )}
        <button
          className="saiz-screen-summary-button"
          style={{
            borderRadius:
              brandCode === BrandConstants.TOMTAILOR ||
              brandCode === BrandConstants.MARCOPOLO
                ? "0px"
                : brandCode === BrandConstants.TCHIBO
                ? "100px"
                : "29px",
            right: brandCode === BrandConstants.MARCOPOLO ? "26px" : "30px",
          }}
          onClick={shopNow}
          id="shopNow"
          type="button"
        >
          {t("shopNow")}
        </button>
      </div>
    </SaizMainContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    cal: state.CalculatorReducer,
    product: state.ProductReducer,
    auth: state.AuthReducer,
  };
};

export default connect(mapStateToProps)(ScreenSummary);
